import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Attention histoire non relue`}</strong></p>
    <p><em parentName="p">{`Thème du défi : Le bien contre le mal, sans nuance`}</em></p>
    <p>{`Flynt se réveilla en sursaut. Les cris et les pleures retentissaient dans le village. Il sortit de la forêt en courant, les flammes destructrices rasaient le paysage de sa jeunesse. L’événement pour lequel il se préparait depuis tout ce temps se déroulait devant ses yeux, le démon Razor attaquait son village.`}</p>
    <p>{`Il y a plusieurs années, Flynt, un jeune orphelin du village, se promenait sur une paisible plage quand il aperçut le corps d’un vieillard au loin. Paniqué, il ramena le vieil homme inconscient dans sa hutte. A son réveil, ses premiers mots furent « C’est toi ! Jeune homme ! C’est toi qui sauvera le monde ! » Flynt ne pouvait pas croire qu’un tel destin l’attendait mais un orphelin comme lui n’ayant pas beaucoup d’espoir de se faire une place dans ce monde. Alors que Flynt chassait le vieux fou de sa demeure, celui-ci proposa « Je t’entraînerais aux Arts Elémentaires, tu deviendras puissant… » Poussé dehors par le jeune orphelin, le vieillard tendit la main et pulvérisa sa maison d’une rafale de vent qui semblait s’échapper de son bras. « Tu n’as plus de maison d’où me chasser. Désormais la nature est ta demeure, comme la mienne, comme elle l’a toujours été. Apprends à maîtriser les éléments et tu n’auras plus besoin de maison. » Impressionné par la demonstration du vieil homme, Flynt accepta et son initiation commença.`}</p>
    <p>{`Il avait du mal à croire que cela se passait sous ses yeux. Les flammes démoniaques s’emparait du village alors que des créatures hideuses faisaient régner la terreur. Des cris, des pleurs. La panique était totale. Flynt regardait dans tous les sens, il cherchait son maître, le vieillard qui l’avait formé depuis son plus jeune âge. Des rafales de cendres brulantes fouettaient son visage alors qu’il avançait comme il pouvait à travers le village embrasé. Il devenait de plus en plus difficile de respirer et ses pieds nus souffraient à marcher sur un sol de braises jonché de copeaux enflammés. Heureusement pour lui il avait appris à maitriser ses douleurs au cours de son entraînement.`}</p>
    <p>{`Debout en équilibre sur une jambe, l’autre pied à hauteur de sa taille, il était dans cette position depuis maintenant une heure. Il n’osait plus demander à son maître pourquoi les séances étaient aussi douloureuse car il connaissait la réponse « Il faut développer ses réceptacles pour entrer en communion avec les éléments » et ces positions étaient censées aider. Une heure plus tard, le vieux sage le frappait à l’abdomen avec une branche d’arbre. Encore une fois, la douleur était un mal nécessaire selon le vieil homme. « Il y a quatre éléments, l’eau, l’air, la terre et le feu, tu ne dois faire qu’un avec eux » ajouta-t-il. Flynt n’osait plus poser de questions. Quelques mois plus tard, il pouvait générer des courants d’air dans la paume de ses mains.`}</p>
    <p>{`Les cendres brûlaient son visage et s’accumulaient dans sa longue chevelure mais il ne perdait pas espoir de retrouver le maître au milieu de la cohue. Il aurait aimé pouvoir aider tous ces gens mais ses efforts seraient vains tant que Razor et ses sbires rôdaient. Sur sa droite un monstre arrivait. Une horrible créature démoniaque qui ressemblait à un sanglier gris orné de cornes en spirales. Flynt se souvint de son entraînement. Les deux jambes fléchies, un pied devant l’autre, il sauta d’une légère impulsion, aidé des vents divins et atterrit sur la tête de son ennemi. Il arracha ses cornes tout en décollant et retomba derrière la bête. Alors qu’un autre démon courrait vers lui, il vit enfin son maître. Celui-ci était au prises avec Razor lui-même. Il accourra vers son mentor mais il était trop tard. Le gigantesque démon l’avait transpercé de son épée couverte d’épine d’acier. Le pauvre homme s’effondra. Anéanti par la vision de cet assassinat, Flynt poussa un cri de rage. Il arriva en pleurs sur le cadavre du vieillard. Celui-ci dit dans son dernier souffle « Il y a quatre éléments, l’eau, l’air, la terre et le feu… Le feu… tu ne dois faire qu’un… » Est-ce vraiment tout ce qu’il avait à lui dire ? Comment allait-il vaincre le démon ? Flynt se retourna pour faire face à Razor, l’ignoble démon qui possédait un corps noir argileux aux reflets rouges vermillons. Une fumée poussiéreuse s’échappait de ses narines de taureaux alors que sa gueule crachait des flammes. Il leva sa gigantesque épée au dessus de sa tête. Celle-ci retombait sur la position de Flynt qui fit un bond en arrière, aidé des vents, esquivant le coup de justesse.`}</p>
    <p>{`La veille du combat, le vieil homme semblait déjà se douter de l’attaque de Razor. « Tu n’es pas prêt » lui disait-il avec désolation. Flynt, les yeux fermés, était en pleine méditation de combat. « Quand les flammes de l’enfer se jetteront sur toi, ce ne sont pas ces quelques bourrasques qui vont les vaincre… »`}</p>
    <p>{`Face a démon, Flynt ne savait pas comment attaquer. Il joint ses deux mains et projeta un tourbillon d’air divin vers le monstre mais celui-ci plissa à peine des yeux. Son maître était au sol et il ne savait comment s’en sortir. Le diable face à lui lança une boule de feu dans sa direction. Flynt évita le projectile mais l’explosion à l’impact le projeta sur plusieurs mètres. Ses vêtements étaient en feu, il se roulait sur le sol de braise pour tenter de les éteindre. « Il y a quatre éléments, l’eau, l’air, la terre, le feu, tu ne dois faire qu’un avec eux », la phrase résonnait dans sa tête alors qu’il se tortillait de douleur.`}</p>
    <p>{`Le monstre cornu approchait à pas lourd vers le jeune homme affaibli. Le démon Razor leva son arme à nouveau, au dessus du corps de l’adolescent qui se roulait dans le feu… Le feu… Le feu… « tu ne dois faire qu’un… » Flynt avait compris. Il se redressa, plongea ses mains dans les braises enflammées et regarda la bête qui s’apprêtait à l’achever. Il sentit une vague incandescente venant de ses mains envahir son corps. Son corps pris feu dans une explosion d’énergie. Razor fut projeté par la déflagration. Le jeune homme ne faisait plus qu’un avec le feu. Il fixait le démon d’un air déterminé et d’un geste, envoya une tornade incandescente vers son ennemi. Terrassé, le démon se consumait dans les flammes. Flynt avait gagné. Affaibli, il s’effondra. Le feu de son corps s’éteignait et les cendres retombait. Il ne faisait plus qu’un avec la terre.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      